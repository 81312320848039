<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom bgi-no-repeat bgi-size-800 bgi-attachment-fixed"
    style="background-image: url('media/illustrations/verification.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-6">
        <h1 class="logo display-5 text-primary">Bauwise</h1>
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework text-center"
          id="kt_verify_email_form"
        >
          <div class="text-center mb-10" v-if="loading">
            <h1 class="text-dark mb-3">Please wait while we are processing your request...</h1>
          </div>
          <div v-else>
            <!--begin::Heading-->
            <div class="text-center mb-10">
              <!--begin::Title-->
              <h1
                class="text-dark mb-3"
              >{{ verifyAccObj.needsSignup ? verifyAccMsg : verifyAccMsg }}</h1>
              <!--end::Title-->

              <!--begin::Link-->
              <div class="text-gray-400 fw-bold fs-4">{{ verifyAccObj.msg }}</div>
              <!--end::Link-->
            </div>
            <!--begin::Heading-->
          </div>
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import router from "@/router/clean";
import CookieService from "@/core/services/CookieService";

export default defineComponent({
  name: "invite-external",
  props: ['token'],
  components: {
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const verifyAccMsg = ref('Inviting User...');
    const verifyAccObj = reactive({
      msg: 'Checking if account exists...',
      needsSignup: false,
      token: '',
      redirect: '',
      email: ''
    })
    const loading = ref(true);
    const loginButtonMsg = reactive({
      msg: 'Login to your account',
      isLoggedIn: false,
      redirect: ''
    })
    const submitButton = ref<HTMLElement | null>(null);

    onBeforeMount(() => {
      setTimeout(() => {
        store.dispatch(Actions.CHECK_ACCOUNT_EXISTS, { token: props.token })
          .then((response) => {
            CookieService.saveCookie(response.data.tenant, "X-TEMP-TENANT");
            localStorage.setItem("lang", response.data.language)

            if (!response.data.needs_signup) {
              loading.value = false;
              verifyAccMsg.value = 'Account Invited Successfully!'
              verifyAccObj.msg = `Redirecting...`
              verifyAccObj.needsSignup = false
              // Check if current user accepting invite equals email inside invitation link
              if (store.getters.currentUser.email !== response.data.email) {
                loginButtonMsg.msg = 'Login to your account';
                loginButtonMsg.isLoggedIn = false;
                setTimeout(() => {
                  router.push({ name: 'sign-in', params: { email: response.data.email }, query: { redirect: `/${response.data.link}` } })
                }, 2000)
                return;
              }
              // Verifies Access Token
              store.dispatch(Actions.VERIFY_AUTH).then(() => {
                loginButtonMsg.msg = `Continue to company: ${response.data.tenant}.`
                loginButtonMsg.isLoggedIn = true
                loginButtonMsg.redirect = response.data.link
                console.log(response.data.link)
                setTimeout(() => {
                  // Queries get dropped 
                  // router.push({ path: '/' + response.data.link })
                  window.location.href = `/#/${response.data.link}`
                }, 2000)
              }).catch(() => {
                console.log('Not Logged In')
              })
              return;
            }
            loading.value = false;
            verifyAccMsg.value = 'Account Not Found!'
            verifyAccObj.token = `${props.token}`
            verifyAccObj.msg = `It doesn't look like you have an account with us. Redirecting to our sign up page.`
            verifyAccObj.needsSignup = true
            verifyAccObj.email = response.data.email
            verifyAccObj.redirect = response.data.link
            localStorage.setItem('tempEmail', response.data.email)
            router.push({ name: 'sign-up', params: { token: props.token, email: response.data.email }, query: { redirect: response.data.link } })

          }).catch((error) => {
            loading.value = false;
            verifyAccObj.msg = "Sorry, seems like something went wrong during the verification process."
            verifyAccObj.needsSignup = false
          })
      }, 1000)
    })

    return {
      // onSubmitEmailVerification,
      // verifyEmail,
      submitButton,
      verifyAccMsg,
      verifyAccObj,
      loading,
      loginButtonMsg
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/sass/style";

.bgi-size-800 {
  background-size: 800px;
}
</style>