
import { defineComponent, ref, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import router from "@/router/clean";
import CookieService from "@/core/services/CookieService";

export default defineComponent({
  name: "invite-external",
  props: ['token'],
  components: {
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const verifyAccMsg = ref('Inviting User...');
    const verifyAccObj = reactive({
      msg: 'Checking if account exists...',
      needsSignup: false,
      token: '',
      redirect: '',
      email: ''
    })
    const loading = ref(true);
    const loginButtonMsg = reactive({
      msg: 'Login to your account',
      isLoggedIn: false,
      redirect: ''
    })
    const submitButton = ref<HTMLElement | null>(null);

    onBeforeMount(() => {
      setTimeout(() => {
        store.dispatch(Actions.CHECK_ACCOUNT_EXISTS, { token: props.token })
          .then((response) => {
            CookieService.saveCookie(response.data.tenant, "X-TEMP-TENANT");
            localStorage.setItem("lang", response.data.language)

            if (!response.data.needs_signup) {
              loading.value = false;
              verifyAccMsg.value = 'Account Invited Successfully!'
              verifyAccObj.msg = `Redirecting...`
              verifyAccObj.needsSignup = false
              // Check if current user accepting invite equals email inside invitation link
              if (store.getters.currentUser.email !== response.data.email) {
                loginButtonMsg.msg = 'Login to your account';
                loginButtonMsg.isLoggedIn = false;
                setTimeout(() => {
                  router.push({ name: 'sign-in', params: { email: response.data.email }, query: { redirect: `/${response.data.link}` } })
                }, 2000)
                return;
              }
              // Verifies Access Token
              store.dispatch(Actions.VERIFY_AUTH).then(() => {
                loginButtonMsg.msg = `Continue to company: ${response.data.tenant}.`
                loginButtonMsg.isLoggedIn = true
                loginButtonMsg.redirect = response.data.link
                console.log(response.data.link)
                setTimeout(() => {
                  // Queries get dropped 
                  // router.push({ path: '/' + response.data.link })
                  window.location.href = `/#/${response.data.link}`
                }, 2000)
              }).catch(() => {
                console.log('Not Logged In')
              })
              return;
            }
            loading.value = false;
            verifyAccMsg.value = 'Account Not Found!'
            verifyAccObj.token = `${props.token}`
            verifyAccObj.msg = `It doesn't look like you have an account with us. Redirecting to our sign up page.`
            verifyAccObj.needsSignup = true
            verifyAccObj.email = response.data.email
            verifyAccObj.redirect = response.data.link
            localStorage.setItem('tempEmail', response.data.email)
            router.push({ name: 'sign-up', params: { token: props.token, email: response.data.email }, query: { redirect: response.data.link } })

          }).catch((error) => {
            loading.value = false;
            verifyAccObj.msg = "Sorry, seems like something went wrong during the verification process."
            verifyAccObj.needsSignup = false
          })
      }, 1000)
    })

    return {
      // onSubmitEmailVerification,
      // verifyEmail,
      submitButton,
      verifyAccMsg,
      verifyAccObj,
      loading,
      loginButtonMsg
    };
  },
});
